import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Image from './Image';
import { COLORS, BREAKPOINTS } from '../styles/constants';

const useStyles = makeStyles(theme => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  featuredImage: {
    maxHeight: 1,
    [BREAKPOINTS.md]: {
      maxHeight: 200,
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.5)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: `0 ${theme.spacing(5)}px`,
      paddingBottom: theme.spacing(5),
    },
  },
  link: {
    color: COLORS.lightWhite,
  },
}));

export default function FeaturedPost() {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      {
        allMarkdownRemark(
          sort: { fields: [frontmatter___title] }
          filter: { frontmatter: { featured: { eq: "true" } } }
        ) {
          edges {
            node {
              id
              html
              excerpt
              frontmatter {
                title
                path
                date
                featured
                lede
                image
              }
            }
          }
        }
      }
    `,
  );
  const classes = useStyles();
  let post = allMarkdownRemark.edges[0].node;

  return (
    <Paper className={classes.mainFeaturedPost}>
      <div style={{ width: '100%' }}>
        <Image
          className={classes.featuredImage}
          src={post.frontmatter.image}
          style={{ position: 'unset' }}
        />
        <div style={{ flex: 1 }} />
        <div className={classes.overlay} />
      </div>
      <div className={classes.mainFeaturedPostContent}>
        <Typography component="h1" variant="h3" color="inherit" gutterBottom>
          {post.frontmatter.title}
        </Typography>
        <Typography variant="h5" color="inherit" paragraph>
          {post.excerpt}
        </Typography>
        <Link
          className={classes.link}
          variant="subtitle1"
          to={post.frontmatter.path}
        >
          Continue reading…
        </Link>
      </div>
    </Paper>
  );
}
