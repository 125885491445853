import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Img from 'gatsby-image';

const useStyles = makeStyles(theme => ({
  image: {
    borderRadius: '50%',
  },
}));

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "dan.jpeg" }) {
            childImageSharp {
              fixed(width: 90, height: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        const classes = useStyles();
        return (
          <div
            className={classes.image}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 16,
              maxWidth: '100%',
            }}
          >
            <Img fixed={data.placeholderImage.childImageSharp.fixed} />
          </div>
        );
      }}
    />
  );
};
