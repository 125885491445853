import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';

import BlogLayout from '../components/BlogLayout';
import { COLORS, BREAKPOINTS } from '../styles/constants';
import FeaturedPost from '../components/FeaturedPost';
import DanImage from '../components/DanImage';
import Articles from '../components/Articles';
import SEO from '../components/seo';
import { spacing } from '../styles/constants';

const useStyles = makeStyles(theme => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  featuredImage: {
    position: 'unset',
    maxHeight: 400,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
  link: {
    color: COLORS.plainice,
  },
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  sidebarAboutBox: {
    padding: spacing(3),
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    paddingTop: 16,
    [BREAKPOINTS.sm]: {
      paddingTop: 24,
    },
    [BREAKPOINTS.md]: {
      paddingTop: 32,
    },
  },
  about: {
    marginBottom: 16,
  },
  socialLink: {
    color: COLORS.plainice,
    textDecoration: 'none',
  },
}));

const featuredPosts = [];

const social = [
  {
    name: 'GitHub',
    link: 'https://github.com/amozoss',
  },
  {
    name: 'Indie Hackers',
    link: 'https://www.indiehackers.com/plainice',
  },
  {
    name: 'Twitch',
    link: 'https://www.twitch.tv/plainice_',
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/plainice_',
  },
];

export default function Blog() {
  const classes = useStyles();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            about
          }
        }
      }
    `,
  );

  return (
    <BlogLayout isBlog>
      <SEO
        title="PlainIce Blog"
        description={
          'I blog about programming, live code streaming, making side projects, or anything I am interested in. I have worked several different jobs including mobile (Android, iOS, React Native), web (React, Rails), and backend (Golang, kubernetes).'
        }
      />
      <CssBaseline />
      <Container maxWidth="lg">
        <main className={classes.main}>
          <FeaturedPost />
          {/* Sub featured posts */}
          <Grid container spacing={4}>
            {featuredPosts.map(post => (
              <Grid item key={post.title} xs={12} md={6}>
                <CardActionArea component="a" href="#">
                  <Card className={classes.card}>
                    <div className={classes.cardDetails}>
                      <CardContent>
                        <Typography component="h2" variant="h5">
                          {post.title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          {post.date}
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                          {post.description}
                        </Typography>
                        <Typography variant="subtitle1" color="primary">
                          Continue reading...
                        </Typography>
                      </CardContent>
                    </div>
                    <Hidden xsDown>
                      <CardMedia
                        className={classes.cardMedia}
                        image="https://source.unsplash.com/random"
                        title="Image title"
                      />
                    </Hidden>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
          {/* End sub featured posts */}
          <Grid container spacing={4} className={classes.mainGrid}>
            {/* Main content */}
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                From the Freezer
              </Typography>
              <Divider />
              <Articles />
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={0} className={classes.sidebarAboutBox}>
                <Grid container>
                  <Grid className={classes.about} item xs={12} sm={3} md={12}>
                    <Typography variant="h2" gutterBottom>
                      About
                    </Typography>
                    <DanImage />
                  </Grid>
                  <Grid item xs={12} sm={9} md={12}>
                    <Typography
                      variant="subtitle1"
                      dangerouslySetInnerHTML={{
                        __html: site.siteMetadata.about,
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.sidebarSection}
              >
                Social
              </Typography>
              <div className={classes.column}>
                {social.map(network => (
                  <a
                    className={classes.socialLink}
                    href={network.link}
                    key={network.link}
                  >
                    <Typography variant="subtitle1">{network.name}</Typography>
                  </a>
                ))}
              </div>
            </Grid>
            {/* End main content */}
            {/* Sidebar */}
            {/* End sidebar */}
          </Grid>
        </main>
      </Container>
    </BlogLayout>
  );
}
