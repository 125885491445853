import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Link, useStaticQuery, graphql } from 'gatsby';

import formatDate from '../formatDate';

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  row: {
    margin: `${theme.spacing(4)}px 0`,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    margin: `${theme.spacing(4)}px 0`,
  },
  subtitle: {
    marginTop: theme.spacing(4),
  },
}));

const Articles = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: { frontmatter: { published: { eq: "true" } } }
        ) {
          edges {
            node {
              id
              excerpt
              frontmatter {
                title
                date
              }
              ...BlogPost
            }
          }
        }
      }
    `,
  );

  const classes = useStyles();
  return (
    <div>
      {allMarkdownRemark.edges.map(({ node }) => {
        return (
          <Link className={classes.link} to={node.frontmatter.path}>
            <div key={node.id} className={classes.row}>
              <Typography variant="h2">{node.frontmatter.title}</Typography>
              <div className={classes.subtitle}>
                <Typography variant="subtitle1">
                  {formatDate(node.frontmatter.date)}
                </Typography>
              </div>
              <Typography variant="body1">{node.excerpt}</Typography>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default Articles;
