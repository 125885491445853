import moment from 'moment';

let localeData = moment.localeData();
let format = localeData.longDateFormat('ll');

export default function formatDate(date) {
  date = moment(date, 'YYYY-MM-DD').local();
  return date.format(`${format}`);
}

export function formatRailsDate(date) {
  date = moment(date).local();
  return date.format(`${format}`);
}
